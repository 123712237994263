var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.loading)?_c('center',{staticClass:"ma-12"},[_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":_vm.loading,"absolute":"","top":"","color":"primary"}})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","to":"/app/sales/create-invoice","color":"success"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create Invoice ")],1)],1),_c('v-card-title',[_vm._v(" Products "),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1 table-one",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.invoices,"item-key":"name","show-select":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.businessPartner",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"26","dark":""}},[_c('img',{attrs:{"src":item.img,"alt":""}})]),_c('p',{staticClass:"ma-0 font-weight-medium"},[_vm._v(" "+_vm._s(item.businessPartner.name)+" ")])],1)]}},{key:"item.invoiceId",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","dark":"","icon":""},on:{"click":function($event){return _vm.previewInvoice(item.invoiceId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Preview")])])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'Active')?[_c('v-chip',{attrs:{"color":"success","label":"","small":"","text-color":"white"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-check")]),_vm._v(" "+_vm._s(item.badge)+" ")],1)]:(item.status === 'OVERDUE')?[_c('v-chip',{attrs:{"color":"danger","label":"","small":"","text-color":"white"}},[_vm._v(" "+_vm._s(item.status)+" ")])]:_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"tabindex":"-1","max-width":"900px"},model:{value:(_vm.invoicePreviewDialog),callback:function ($$v) {_vm.invoicePreviewDialog=$$v},expression:"invoicePreviewDialog"}},[_c('v-card',{},[_c('v-card-text',[_c('invoice')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){_vm.invoicePreviewDialog = false}}},[_vm._v("Close")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }