<template>
  <v-row>
    <center v-if="loading" class="ma-12">
       <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          absolute
          top
          color="primary"
      ></v-progress-linear>
    </center>
    <v-col v-else cols="12">
      <base-card>
        <div class="d-flex justify-space-between flex-wrap">
          <v-btn
            class="ma-2"
            dark
            to="/app/sales/create-invoice"
            color="success">
            <v-icon>mdi-plus</v-icon>
            Create Invoice
          </v-btn>
          <!-- <div>
              <v-btn class="ma-2" color="primary">
                  <v-icon>mdi-cog</v-icon>
              </v-btn>
              <v-btn outlined class="ma-2">Import</v-btn>
              <v-btn outlined class="ma-2">Export</v-btn>
          </div> -->
        </div>
        <v-card-title>
          Products
          <v-spacer />
          <v-spacer />
          <v-spacer />
          <v-spacer />

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          />
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="invoices"
          item-key="name"
          show-select
          class="elevation-1 table-one"
          multi-sort
        >
          <template v-slot:item.businessPartner="{item}">
            <div class="d-flex align-center">
              <v-avatar
                class="mr-2"
                size="26"
                dark>
                <img
                  :src="item.img"
                  alt="">
              </v-avatar>
              <p class="ma-0 font-weight-medium">
                {{ item.businessPartner.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.invoiceId="{item}">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="success"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="previewInvoice(item.invoiceId)"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Preview</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    color="danger"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip> -->
            </div>
          </template>
          <template v-slot:item.status="{item}">
            <template v-if="item.status === 'Active'">
              <v-chip
                class=""
                color="success"
                label
                small
                text-color="white"
              >
                <v-icon
                  small
                  left>mdi-check</v-icon>
                {{ item.badge }}
              </v-chip>
            </template>
            <template v-else-if="item.status === 'OVERDUE'">
              <v-chip
                class=""
                color="danger"
                label
                small
                text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
          </template>
        </v-data-table>
      </base-card>
      <v-dialog v-model="invoicePreviewDialog" tabindex="-1" max-width="900px">
             
        <v-card class="">
            <v-card-text>
              <invoice />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary darken-1"
                    text
                    @click="invoicePreviewDialog = false"
                    >Close</v-btn
                >
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Invoice from './Invoice.vue'
  export default {
  components: { Invoice },
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Table One',
    },
    data() {
      return {
        search: '',
        invoicePreviewDialog: false,
        selected: [],
        headers: [
          {
            text: 'Partner',
            align: 'start',
            sortable: false,
            value: 'businessPartner',
          },
          { text: 'Date', value: 'invoicedate' },
          { text: 'Amount Due', value: 'amountDue' },
          { text: 'Total Amount', value: 'totalAmount' },
          { text: 'Due Date', value: 'duedate' },
          { text: 'Due Days', value: 'dueDays' },
          { text: 'Status', value: 'status' },
          { text: 'Action', value: 'invoiceId' },
        ],
      }
    },
    mounted () {
      this.toggleLoading(true)
      this.fetchInvoices()
      this.toggleLoading(false)
    },
    computed: {
      ...mapGetters(['invoices'])
    },
    methods: {
      ...mapActions(['fetchInvoices', 'viewInvoice','toggleLoading']),
      previewInvoice (id) {
        this.invoices.forEach((invoice) => {
          if (invoice.invoiceId === id) {
            this.viewInvoice(invoice)
            this.invoicePreviewDialog = true
          }
        })
      }
    }
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
